import { gql, useMutation } from '@apollo/client'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { refreshStoresList, setCreatingUser } from '../../../redux/actions'
import { chevronBack } from 'ionicons/icons'
import axios from 'axios'
import { registerUser } from '../../../firebase'
import useToast from '../../../hooks/useToast'
import { MERCHANT_ID_REGEX, URL_USER } from '../../../utils/constants'
import { tryTrim } from '../../../utils/helper'

const STORE_INSERT = gql`
  mutation createUser(
    $name: String!
    $email: String!
    $merchantId: String!
    $adquirerBin: String!
    $terminalId: String!
    $encryptionKey: String!
    $uid: String!
    $isAdmin: Boolean!
    $registeredAt: String
  ) {
    createUser(
      name: $name
      email: $email
      merchant_id: $merchantId
      adquirer_bin: $adquirerBin
      terminal_id: $terminalId
      encryption_key: $encryptionKey
      uid: $uid
      is_admin: $isAdmin
      registered_at: $registeredAt
    ) {
      name
      email
      merchant_id
      adquirer_bin
      terminal_id
      encryption_key
      uid
      is_admin
      registered_at
    }
  }
`

export const AddUser: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [merchantId, setMerchantId] = useState('')
  const [adquirerBin, setAdquirerBin] = useState(process.env.REACT_APP_ACQUIRER_BIN)
  const [terminalId, setTerminalId] = useState(process.env.REACT_APP_TERMINAL_ID)
  const [encryptionKey, setEncryptionKey] = useState('')
  const handleDismiss = () => {
    dispatch(setCreatingUser(false))
  }
  const [CreateUser, { loading, error }] = useMutation(STORE_INSERT)
  const { toast } = useToast()

  if (loading) {
    return (
      <IonLoading
        message={t('message.please_wait')}
        isOpen={true}
        duration={0}
      />
    )
  }
  if (error) {
    console.error(error)
    return <div>{error}</div>
  }

  const addStore = async (event: { preventDefault: () => void }) => {
    event.preventDefault()

    if (!name) {
      toast(t('error.user_name_required'))
      return
    }

    if (!email) {
      toast(t('error.email_required'))
      return
    }

    if (!merchantId) {
      toast(t('error.merchant_id_required'))
      return
    }

    if (!adquirerBin) {
      toast(t('error.adquirer_bin_required'))
      return
    }

    if (!terminalId) {
      toast(t('error.terminal_id_required'))
      return
    }

    if (!encryptionKey) {
      toast(t('error.encryption_key_required'))
      return
    }

    if (!merchantId.match(MERCHANT_ID_REGEX)) {
      toast(t('error.merchant_id_validation'))
      return
    }

    const password = Math.random().toString(36)
    const result: any = await registerUser(email, password, merchantId)

    if (!result.success) {
      if (result?.error?.code === 'auth/merchant-id-already-exists') {
        toast(t('error.merchant_id_already_in_use'))
      } else {
        console.error({ result })
        toast(t('error.generic'))
      }
    } else {
      const uid = result?.user?.uid
      const registeredAt = result?.user?.registered_at
      const isAdmin = false
      await axios({
        method: 'GET',
        headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
        url: `${URL_USER}?email=${email}&merchantId=${merchantId}`
      }).then(() => {
        toast(t('error.email_and_merchant_id_duplicated'))
      }).catch(async () => {
        await CreateUser({
          variables: {
            name,
            email: tryTrim(email),
            merchantId: tryTrim(merchantId),
            adquirerBin: tryTrim(adquirerBin),
            terminalId: tryTrim(terminalId),
            encryptionKey: tryTrim(encryptionKey),
            uid,
            isAdmin,
            registeredAt
          }
        })
        dispatch(refreshStoresList(true))
        handleDismiss()
      })
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle> {t('title.new_user')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form onSubmit={addStore} id='store-form'>
          <IonList lines="full" className="ion-no-margin ion-no-padding">
            <IonItem>
              <IonLabel position="stacked">{t('field.name')}</IonLabel>
              <IonInput
                required
                type="text"
                onInput={(e: any) => {
                  setName(e.target.value)
                }}
                value={name}
                name="name"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('field.email')}</IonLabel>
              <IonInput
                required
                type="email"
                onInput={(e: any) => {
                  setEmail(e.target.value)
                }}
                value={email}
                name="email"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('field.merchant_id')}</IonLabel>
              <IonInput
                required
                type="number"
                onInput={(e: any) => {
                  setMerchantId(e.target.value)
                }}
                value={merchantId}
                name="merchantId"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('field.adquirer_bin')}</IonLabel>
              <IonInput
                required
                type="text"
                onInput={(e: any) => {
                  setAdquirerBin(e.target.value)
                }}
                value={adquirerBin}
                name="adquirerBin"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">{t('field.terminal_id')}</IonLabel>
              <IonInput
                required
                type="text"
                onInput={(e: any) => {
                  setTerminalId(e.target.value)
                }}
                value={terminalId}
                name="terminalId"
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">
                {t('field.encryption_key')}
              </IonLabel>
              <IonInput
                required
                type="text"
                onInput={(e: any) => {
                  setEncryptionKey(e.target.value)
                }}
                value={encryptionKey}
                name="encryptionKey"
              />
            </IonItem>
          </IonList>

          <div className="ion-padding">
            <IonButton expand="block" type="submit" className="ion-no-margin">
              {t('action.create_store')}
            </IonButton>
          </div>
        </form>
      </IonContent>
    </IonPage>
  )
}

export default AddUser
