import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setEditingStore } from '../../../redux/actions'
import { chevronBack } from 'ionicons/icons'
import axios from 'axios'
import { User } from '../../../interfaces/interfaces'
import useToast from '../../../hooks/useToast'
import { URL_UPDATE_STORE } from '../../../utils/constants'
import { tryTrim } from '../../../utils/helper'

export const EditStore: React.FC = () => { // TODO remove admin edit logic
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const storeData: User = useSelector((state: any) => state.stores.current)
  const [name, setName] = useState(storeData.name)
  const [email, setEmail] = useState(storeData.email)
  const [merchantId, setMerchantId] = useState(storeData.merchant_id)
  const [adquirerBin, setAdquirerBin] = useState(storeData.adquirer_bin)
  const [terminalId, setTerminalId] = useState(storeData.terminal_id)
  const [encryptionKey, setEncryptionKey] = useState(storeData.encryption_key)
  const [busy, setBusy] = useState<boolean>(false)
  const { toast } = useToast()

  const handleUpdateStoreError: any = {
    'auth/merchant-id-already-exists': () => toast(t('error.merchant_id_already_in_use')),
    'auth/email-already-exists': () => toast(t('error.email_already_exists')),
    'auth/admin-email': () => toast(t('error.admin_email_not_allowed')),
    default: () => toast(t('error.generic'))
  }

  const handleDismiss = () => {
    dispatch(setEditingStore(false))
  }

  const updateStore = async (event: { preventDefault: () => void }) => {
    event.preventDefault()
    setBusy(true)

    const data = {
      uid: storeData.uid,
      name,
      currentEmail: tryTrim(storeData.email),
      email: tryTrim(email),
      currentMerchantId: tryTrim(storeData.merchant_id),
      merchant_id: tryTrim(merchantId),
      adquirer_bin: tryTrim(adquirerBin),
      terminal_id: tryTrim(terminalId),
      encryption_key: tryTrim(encryptionKey)
    }

    await axios({
      method: 'POST',
      headers: { Authorization: localStorage.getItem('AUTH_TOKEN') || '' },
      url: URL_UPDATE_STORE,
      data
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        (handleUpdateStoreError[error.response.data.code] || handleUpdateStoreError.default)()
        setBusy(false)
      })
  }

  return (
    <IonPage className='edit-store-page'>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={handleDismiss}>
              <IonIcon icon={chevronBack} onClick={handleDismiss} />
            </IonButton>
          </IonButtons>
          <IonTitle> {t('title.edit_store')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {busy && <IonLoading message={t('message.please_wait')} isOpen={busy} duration={0} />}

        <div>
          <form onSubmit={updateStore}>
            <IonList lines="full" className="ion-no-margin ion-no-padding">
              <IonItem>
                <IonLabel position="stacked">{t('field.name')}</IonLabel>
                <IonInput
                  required
                  autocomplete="new-password"
                  type="text"
                  onIonChange={(e: any) => setName(e.target.value)}
                  value={name}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.email')}</IonLabel>
                <IonInput
                  required
                  autocomplete="new-password"
                  type="email"
                  onIonChange={(e: any) => setEmail(e.target.value)}
                  value={email}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.merchant_id')}</IonLabel>
                <IonInput
                  required
                  autocomplete="new-password"
                  type="text"
                  onIonChange={(e: any) => setMerchantId(e.target.value)}
                  value={merchantId}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.adquirer_bin')}</IonLabel>
                <IonInput
                  required
                  autocomplete="new-password"
                  type="text"
                  onIonChange={(e: any) => setAdquirerBin(e.target.value)}
                  value={adquirerBin}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.terminal_id')}</IonLabel>
                <IonInput
                  required
                  autocomplete="new-password"
                  type="text"
                  onIonChange={(e: any) => setTerminalId(e.target.value)}
                  value={terminalId}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t('field.encryption_key')}</IonLabel>
                <IonInput
                  required
                  autocomplete="new-password"
                  type="text"
                  onIonChange={(e: any) => setEncryptionKey(e.target.value)}
                  value={encryptionKey}
                />
              </IonItem>
            </IonList>

            <div className="ion-padding">
              <IonButton expand="block" type="submit" className="ion-no-margin">{t('action.save_changes')}</IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default EditStore
